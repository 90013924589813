/* globals $, ga, appConfig, pageData, MediaQuery, Modernizr, objectFitImages, Swiper */

/* exported App */

'use strict';

var App = (function() {

    var config = {
            selectors: {
                window: window,
                html: 'html',
                body: 'body',

                smoothScrolling: '.js-smooth-scroll',

                page: '.js-page',
                pageWrapper: '#js-page-stack',
                pageTrigger: '.js-page-trigger',

                header: '.js-header',
                nav: '.js-navigation',
                navToggle: '.js-nav-toggle',
                navClose: '.js-nav-close',

                secondaryNav: '.js-secondary-navigation',
                contactToggle: '.js-contact-toggle',
                imprintToggle: '.js-imprint-toggle',

                homeSlider: '.js-home-slider',

                remoteCaptionContainer: '.js-remote-caption-container',
                remoteCaptionContent: '.js-remote-caption-content',
                remoteCaptionSource: '.js-remote-caption-source',
                remoteCaptionToggle: '.js-remote-caption-toggle',

                studentListContainer: '.js-student-list-container',
                studentList: '.js-student-list',
                studentListLink: '.js-student-list-link',
                studentListHeader: '.js-student-list-header',
                studentListStudent: '.js-student-list-student',
                studentListScroll: '.js-scroll-container',
                studentListMarker: '.js-student-list-marker',
                studentListPhotos: '.js-student-photo-list img',

                slideshow: '.js-slideshow',

                collapseableContentContainer: '.js-content-toggle-container',
                collapseableContentOuter: '.js-content-toggle-outer',
                collapseableContentToggleAll: '.js-content-toggle-all',
                collapseableContentToggle: 'h1,h2,h3,h4,h5,h6',

                projectList: '.js-project-list',
                projectListContent: '.js-project-list-content',
                projectListColumn: '.js-project-list-column',
                projectListScrollLeft: '.js-project-list-scroll-left',
                projectListScrollRight: '.js-project-list-scroll-right',
                projectListCategoryLink: '.js-project-list-category-link',

                projectPreview: '.js-project-preview',

                project: '.js-project',
                projectMediaContainer: '.js-project-media-container',
                projectMedia: '.js-project-media',
                projectMediaToggle: '.js-project-media-toggle',
                projectLightbox: '.js-project-lightbox',
                projectLightboxClose: '.js-project-lightbox-close',
                projectMediaSlideshow: '.js-project-media-slideshow',

                workList: '.js-work-list',
                workListSlideshow: '.js-work-list-slideshow',
                workListIndexToggle: '.js-work-list-index-toggle',
                workListIndexThumb: '.js-work-list-index-thumb',

                personList: '.js-person-list',
                personListContentWrapper: '.js-content-item--person-list',
                personListPerson: '.js-person-list-person',
                personListInfoToggle: '.js-person-list-info-toggle',
                personListInfo: '.js-person-list-info',

                languageSwitcher: '.js-language-switcher',

                tocContainer: '.js-toc-container',
                tocScrollContainer: '.js-toc-scroll-container',
                tocItemList: '.js-toc-list',
                tocItem: '.js-toc-item',
                tocBlock: '.js-toc-block',
            },

            smoothScrollingSpeed: 600,
            objectFitInterval: 3000,
            transitionDuration: 0, // CSS transition duration, read in later

            slideshowSettings: {
                home: {
                    speed: 800,
                    loop: true,
                    autoplay: {
                        delay: 4000,
                    },
                    direction: 'horizontal',
                    grabCursor: true,
                    keyboard: true,
                    pagination: {
                        type: 'bullets',
                        el: '.js-slideshow-pagination',
                        clickable: true,
                        hideOnClick: false,
                        bulletClass: 'pagination-bullet',
                        bulletActiveClass: 'is-active',
                        modifierClass: 'pagination--',
                        renderBullet: function (index, className) {
                            return '<span class="' + className + '">' + (index + 1) + '</span>';
                        }
                    },
                    navigation: {
                        prevEl: '.js-slideshow-prev',
                        nextEl: '.js-slideshow-next',
                    }
                },
                fullWidth: {
                    slidesPerView: 'auto',
                    speed: 600,
                    direction: 'horizontal',
                    slideToClickedSlide: true,
                    grabCursor: true,
                    keyboard: true,
                    mousewheel: false,
                    navigation: {
                        prevEl: '.js-slideshow-prev',
                        nextEl: '.js-slideshow-next',
                    }
                },
                projectLightbox: {
                    speed: 600,
                    loop: true,
                    direction: 'vertical',
                    grabCursor: true,
                    keyboard: true,
                    mousewheel: {
                        invert: true,
                        forceToAxis: true,
                        sensitivity: 1,
                    },
                    navigation: {
                        prevEl: '.js-slideshow-prev',
                        nextEl: '.js-slideshow-next',
                    }
                },
                workList: {
                    speed: 600,
                    loop: true,
                    direction: 'vertical',
                    grabCursor: true,
                    keyboard: true,
                    mousewheel: {
                        invert: true,
                        forceToAxis: true,
                        sensitivity: 1,
                    },
                    pagination: {
                        type: 'custom',
                        el: '.js-work-index-pagination',
                        clickable: false,
                        hideOnClick: false,
                        renderCustom: function (swiper, current, total) {
                            return '<span>' + current + '</span>' + total;
                        },
                    },
                    navigation: {
                        prevEl: '.js-slideshow-prev',
                        nextEl: '.js-slideshow-next',
                    }
                }
            }
        },

        data = {
            windowHeight: 0,
            headerHeight: 0,
        },

        selectors = {},

        elements = {}, $e,



    init = function(_config) {
        // Extend module config
        $.extend(true, config, _config || {});

        // Read config from meta tags
        readCSSConfig();

        // Cache all elements for later use
        cacheElements();

        // DOM and event bindings
        setupBindings();

        // Pub/sub
        setupEvents();

        // Object-fit images
        objectFitPolyfill();

        setupPageTransition();
    },



    cacheElements = function() {
        // Iterate over all selectors in config and store them as jQuery objects
        $.each(config.selectors, function(elementName, selector) {
            elements[elementName] = $(selector);
        });

        // Shortcuts
        selectors = config.selectors;
        $e = elements;
    },


    // DOM bindings
    setupBindings = function() {

        // Toggle mobile navigation
        $(document).on('click', selectors.navToggle, function(event){
            if ($e.body.hasClass('is-contact-visible')) {
                $e.body.removeClass('is-contact-visible');
            }
            else if ($e.body.hasClass('is-imprint-visible')) {
                $e.body.removeClass('is-imprint-visible');
            }
            else {
                $e.body.toggleClass('is-nav-visible');
            }
            event.preventDefault();
        });

        // Close mobile navigation
        $(document).on('click touchend', selectors.navClose, function (event) {
            $e.body.removeClass('is-nav-visible');
            $e.body.removeClass('is-contact-visible');
            $e.body.removeClass('is-imprint-visible');
            event.preventDefault();
        });

        // Toggle secondary navigation
        $(document).on('mouseenter', selectors.secondaryNav, function(event){
            $e.body.addClass('is-secondary-nav-visible');
        }).on('mouseleave', selectors.secondaryNav, function(event){
            if ($e.body.hasClass('is-contact-visible') || $e.body.hasClass('is-imprint-visible')) {
                return;
            }
            $e.body.removeClass('is-secondary-nav-visible');
        });

        // Toggle contact info
        $(document).on('click', selectors.contactToggle, function(event){
            $e.body.toggleClass('is-contact-visible');
            $e.body.removeClass('is-imprint-visible');
            event.preventDefault();
        });

        // Toggle contact info
        $(document).on('click', selectors.imprintToggle, function(event){
            $e.body.toggleClass('is-imprint-visible');
            $e.body.removeClass('is-contact-visible');
            event.preventDefault();
        });

        // Toggle language
        $(document).on('click', selectors.languageSwitcher, function (event) {
            var $toggle = $(this),
                newLanguage = $toggle.data('lang');
            $e.html.attr('data-active-lang', newLanguage);
        });

        // Toggle slider captions
        $(document).on('click', selectors.remoteCaptionToggle, function (event) {
            $('body').toggleClass('is-slideshow-info-visible');
            var $slideshow = $(selectors.homeSlider),
                swiper = $slideshow.length ? $slideshow.get(0).swiper : null;
            if (swiper) {
                swiper.autoplay.stop()
            }
        });

        // Make anchor links scroll smoothly
        $(document).on('click', selectors.smoothScrolling, function(event){
            var $link = $(this),
                $target = $($link.attr('href')),
                scrollTo = $target.offset().top;

            $('html, body').animate({scrollTop: scrollTo}, config.smoothScrollingSpeed);
            event.preventDefault();
        });

        // Resizing? Save to body
        $(window).on('resizestart',   0, function(){ $e.body.addClass('is-resizing'); });
        $(window).on('resizestop', 500, function(){
            $e.body.removeClass('is-resizing');
            data.windowHeight = $(window).height();
            data.headerHeight = $e.header.height();
            $e.body.get(0).style.setProperty('--header-height', data.headerHeight+'px');
        }).trigger('resizestop');

        // Breakpoint change
        $.subscribe('/mediaquery/change', function(_, size) {
            if (MediaQuery.atLeast('small')) {

            }
        });
    },

    // Observers for interaction with other modules
    setupEvents = function() {
        // Update CSS config on mediaquery change
        $.subscribe('/mediaquery/change', readCSSConfig);

        // Show and hide loading spinner when loading over Ajax
        $.subscribe('/ajax/start', function() { $e.body.addClass('is-loading'); });
        $.subscribe('/ajax/stop',  function() { $e.body.removeClass('is-loading'); });

        // Log all ajax errors to console
        $(document).ajaxError(function(event, jqXHR, settings, error) {
            console.error('Ajax request failed: ' + error + ' ('+settings.url+')');
        });
    },

    onStateChange = function() {
        setupSlideshows();
        setupStudentList();
        setupProjectList();
        setupProjectMedia();
        setupWorkList();
        setupPersonList();
        setupCollapseableContent();
        setupToc();
        openExternalLinksInNewTab();
    },


    pageHasTemplate = function(template) {
        return $e.body.hasClass('template-' + template);
    },

    readCSSConfig = function() {
        config.transitionDuration = getCSSConfigValue('transition') || 0;
    },

    getCSSConfigValue = function (key) {
        var configStr = $('.js-config-' + key).css('font-family');
        return configStr ? configStr.trim().slice(1, -1) : null; // browsers re-quote string styles
    },

    trackPageView = function(page){
        if (window.ga && window.ga.create) {
            window.ga('set', 'location', page || window.location.pathname);
            window.ga('send', 'pageview');
        }
    },

    trackCustomEvent = function(category, action, label){
        ga('send', 'event', category, action, label);
    },

    objectFitPolyfill = function () {
        if (Modernizr.objectfit) {
            return;
        }

        if (typeof objectFitImages !== 'function') {
            return;
        }

        var triggerObjectFit = function () {
            objectFitImages(null, {watchMQ: true});
        };

        triggerObjectFit();
        setTimeout(triggerObjectFit, config.objectFitInterval / 2);
        setInterval(triggerObjectFit, config.objectFitInterval);
    },

    openExternalLinksInNewTab = function () {
        $('a')
            .once()
            .filter('[href^="http"], [href^="//"]')
            .not('[href*="' + window.location.host + '"]')
            .attr('rel', 'noopener noreferrer')
            .attr('target', '_blank');
    },

    setupPageTransition = function() {

        var $pageContainer = $('#js-page-stack');

        // DOM setup
        Barba.Pjax.Dom.wrapperId = 'js-page-stack';
        Barba.Pjax.Dom.containerClass = 'js-page';

        var containerSelector = '.' + Barba.Pjax.Dom.containerClass;

        // Sanity checks
        Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

        Barba.Pjax.preventCheck = function (evt, element) {

            // Allow hash links
            if ($(element).attr('href') && $(element).attr('href').indexOf('#') > -1) {
                return true;
            }

            // Run original check
            if (!Barba.Pjax.originalPreventCheck(evt, element)) {
                return false;
            }
            // Disable category filter
            if ($(element).is(selectors.projectListCategoryLink)) {
                return false;
            }
            // Disable downloads
            if (/\.(pdf|doc|docx|zip)$/.test(element.href.toLowerCase())) {
                return false;
            }
            return true;
        };

        // Transitions
        var StackAddTransition = Barba.BaseTransition.extend({
            start: function () {
                this.newContainerLoading.then(this.showNewPage.bind(this));
            },

            storePrevPageCopy: function () {
                this.$clone = $(this.oldContainer).clone(true, true);
                this.$clone.insertBefore($(this.oldContainer));
                this.cleanUpPreviousCopies();
            },

            cleanUpPreviousCopies: function () {
                var $copies = $pageContainer.find(containerSelector);
                $copies.slice(0, -4).remove();
            },

            showNewPage: function () {
                var _this = this;
                var $el = $(this.newContainer);

                this.storePrevPageCopy();

                $el.addClass('is-new');

                // this.done();
                setTimeout(function () {
                    $el.removeClass('is-new');
                    _this.done();
                }, 100);

                setTimeout(function () {
                    scrollToHash();
                }, 600);
            }
        });

        Barba.Pjax.getTransition = function () {
            return StackAddTransition;
        };

        // Views

        var CommonView = Barba.BaseView.extend({
            namespace: 'common',
            onEnter: function () {
                onStateChange();
            },
            onEnterCompleted: function () {
                updateSwiperDimensions();
            },
            onLeaveCompleted: function () { }
        });
        CommonView.init();

        // Disable page reload on clicking same href twice

        /*
        var links = document.querySelectorAll('a[href]');
        var cbk = function (e) {
            if (e.currentTarget.href === window.location.href) {
                e.preventDefault();
                e.stopPropagation();
                Barba.Pjax.goTo(e.currentTarget.href);
            }
        };

        for (var i = 0; i < links.length; i++) {
            links[i].addEventListener('click', cbk);
        }
        */

        // Track page view

        Barba.Dispatcher.on('newPageReady', function () {
            trackPageView();
        });

        // Swap out body class

        var updateBodyClass = function (bodyClass) {
            document.body.setAttribute('class', bodyClass);
        };

        Barba.Dispatcher.on('newPageReady', function (currentStatus, oldStatus, container, newPageRawHTML) {
            var regexp = /\<body\s[^>]*class=["'](.+?)["'].*\>/gi,
                match = regexp.exec(newPageRawHTML);
            if (!match || !match[1]) return;
            updateBodyClass(match[1]);
        });

        var updateNavClasses = function($newItems) {
            $('[data-class-update]').each(function (index) {
                var newClasses = $($newItems[index]).get(0).classList.value;
                $(this).attr('class', newClasses);
            });
        };

        // Update classnames
        Barba.Dispatcher.on('newPageReady', function (currentStatus, oldStatus, container, newPageRawHTML) {
            var $container = $(container);
            var $newItems = $(newPageRawHTML).find('[data-class-update]');
            updateNavClasses($newItems);
            $container.data('navItems', $newItems);
            $container.data('url', currentStatus.url);
        });

        // Store first set of nav items and body class of first container
        var $initialContainer = $(containerSelector);
        $initialContainer.data('navItems', $('[data-class-update]').clone());
        $initialContainer.data('bodyClass', document.body.className);
        $initialContainer.data('url', window.location.href);

        // Click archived page: pop stack and show this one
        $(document).on('click', selectors.pageTrigger, function(){
            var $page = $(this).closest(containerSelector);
            var $prev = $page.prev(containerSelector);
            var $next = $page.nextAll(containerSelector);
            var $navItems = $page.data('navItems');
            var bodyClass = $page.data('bodyClass');
            var newUrl = $page.data('url');

            if ($next.length) {
                $page.addClass('is-moving-to');
                $prev.addClass('is-moving-to-next');
                $next.addClass('is-removing');

                if ($navItems) {
                    updateNavClasses($navItems);
                }

                if (newUrl) {
                    window.history.pushState({}, '', newUrl);
                    Barba.HistoryManager.add(newUrl);
                }
                onStateChange();

                setTimeout(function() {
                    $page.removeClass('is-moving-to');
                    $prev.removeClass('is-moving-to-next');
                    $next.remove();
                    if (bodyClass) {
                        updateBodyClass(bodyClass);
                    }
                }, 1000);
            }
        });

        // Start Barba

        Barba.Pjax.start();
        Barba.Prefetch.init();
    },

    updateSwiperDimensions = function () {
        $('.swiper-container').each(function () {
            var swiper = this.swiper;
            if (swiper) {
                swiper.update();
                setTimeout(function () {
                    swiper.update();
                }, 500);
                setTimeout(function () {
                    swiper.update();
                }, 1000);
                setTimeout(function () {
                    swiper.update();
                }, 1500);
                setTimeout(function () {
                    swiper.update();
                }, 2000);
            }
        });
    },

    setupSlideshows = function () {
        $(selectors.slideshow).once().each(function(){
            var $slideshow = $(this),
                cfgKey = $slideshow.data('config').replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); }),
                cfg = config.slideshowSettings.default;

            if (config.slideshowSettings[cfgKey]) {
                cfg = config.slideshowSettings[cfgKey];
            }

            if ($slideshow.data('remote-caption')) {
                var containerName = $slideshow.data('remote-caption');
                var $container = $(selectors.remoteCaptionContainer + '[data-container="' + containerName + '"]');
                var $target = $container.find(selectors.remoteCaptionContent);

                cfg.on = {
                    slideChangeTransitionStart: function () {
                        var $slide = $(this.slides[this.activeIndex]);
                        var $source = $slide.find(selectors.remoteCaptionSource);
                        $target.html($source.html());
                    },
                };
            }

            var swiperInstance = new Swiper($slideshow, cfg);

        });
    },

    setupCollapseableContent = function () {
        var $outer = $(selectors.collapseableContentOuter).once();
        if (!$outer.length) return;

        var $toggles = $outer.find(selectors.collapseableContentToggle),
            $toggleAll = $outer.find(selectors.collapseableContentToggleAll);

        $toggles.each(function(){
            var $toggle = $(this),
                $content = $toggle.nextUntil(selectors.collapseableContentToggle),
                $wrapper = $content.wrapAll('<div>').parent();

            $toggle.data('open', false);
            $wrapper.slideUp(0, function(){
                $wrapper.addClass('is-setup');
            });

            $toggle.click(function(){
                var open = $toggle.data('open');
                $wrapper[open ? 'slideUp' : 'slideDown']();
                $toggle.data('open', !open);
                $toggle.toggleClass('is-open', !open);
            });
        });

        $toggleAll.each(function(){
            var $toggle = $(this),
                $outer = $toggle.closest(selectors.collapseableContentOuter),
                $container = $outer.find(selectors.collapseableContentContainer),
                $singleToggles = $container.find(selectors.collapseableContentToggle);

            $toggle.click(function(){
                var allOpen = $singleToggles.map(function(){
                        return !!$(this).data('open');
                    }).get().every(function(val) { return val; });
                if (!allOpen) {
                    $singleToggles.data('open', false);
                }
                $singleToggles.click();
                $toggle.toggleClass('is-open', !allOpen);
            });
        });

    },

    scrollToHash = function (hash) {
        if (hash === undefined) {
            hash = window.location.hash || '';
        }
        if (!hash) {
            return;
        }

        var $target = $(hash);
        $target = $target.length ? $target : $('[name=' + hash.slice(1) + ']');
        if ($target.length) {
            $parent = $target.scrollParent();
            $parent.scrollTo($target, config.smoothScrollingSpeed);
            console.log('Scrolling to', $target.get(0));
        }
    },

    setupToc = function () {
        var $container = $(selectors.tocContainer).once();
        if (!$container.length) return;

        var $items = $container.find(selectors.tocItem),
            $list = $container.find(selectors.tocItemList),
            $blocks = $container.find(selectors.tocBlock),
            $scroll = $container.find(selectors.tocScrollContainer);

        $items.on('click', function(){
            var $item = $(this),
                itemID = $item.data('id'),
                $block = $blocks.filter('[data-id=' + itemID + ']');

            if ($block.length) {
                $scroll.scrollTo($block, config.smoothScrollingSpeed, {
                    offset: -$list.outerHeight(),
                });
            }
        });

        $scroll.on('scroll', $.throttle(50, function(){

            // Trigger status class
            var scrollTop = $(this).scrollTop();
            var cutOff = $(this).height() * 1/2;
            $container.toggleClass('is-scrolled', scrollTop > 10);

            // Find mainly visible toc item
            var $activeBlock = $blocks.first();
            $blocks.each(function () {
                var offset = $(this).offset();
                if (offset.top < cutOff) {
                    $activeBlock = $(this);
                }
            });
            var blockID = $activeBlock.data('id');
            var $activeItem = $items.filter('[data-id=' + blockID + ']');
            $items.removeClass('is-active');
            $activeItem.addClass('is-active');
        })).trigger('scroll');

    },

    setupStudentList = function () {
        var $namespace = $(selectors.studentListContainer).once();
        if (!$namespace.length) return;

        var $lists = $namespace.find(selectors.studentList),
            $photos = $namespace.find(selectors.studentListPhotos);

        // Endless scrolling and current scroll marker

        $lists.each(function () {
            var $list = $(this),
                $container = $list.find(selectors.studentListScroll),
                $marker = $list.find(selectors.studentListMarker),
                $activeStudent, $activePhoto, $newActiveStudent,
                $activeHeader, $newActiveHeader;

            // Endless scrolling

            if (whatInput.ask('intent') === 'mouse') {
                setupEndlessScrolling($container);
            }
            else {
                setupFakeEndlessScrolling($container);
            }


            var $headers = $list.find(selectors.studentListHeader);
            var $students = $list.find(selectors.studentListStudent);
            var $listItems = $headers.add($students);

            // Calculate marker position

            var markerPos, markerHeight;
            var updateMarkerPos = function () {
                markerHeight = $marker.outerHeight();
                markerPos = {
                    x: $marker.offset().left + 10,
                    y: $marker.offset().top + (markerHeight / 2),
                };
            };

            $(window).on('resizestop.studentlist', 250, function() {
                setTimeout(updateMarkerPos, 500);
            }).trigger('resizestop.studentlist');
            updateMarkerPos();

            // Find student / header at marker position

            var findAndSetActiveListItem = function () {
                var element = document.elementFromPoint(markerPos.x, markerPos.y);
                if (element) {
                    setActiveListItem(element);
                }
            };

            // Set marked student / header as active

            var setActiveListItem = function (student) {
                var $student = $(student).closest(selectors.studentListStudent);
                var $header = $(student).closest(selectors.studentListHeader);
                $newActiveStudent = $student.length ? $student : null;
                $newActiveHeader = $header.length ? $header : null;

                if ($activeStudent) {
                    $activeStudent.removeClass('is-active');
                    $activePhoto && $activePhoto.removeClass('is-active');
                }
                if ($activeHeader) {
                    $headers.removeClass('is-active');
                }

                if ($newActiveStudent) {
                    $activeStudent = $newActiveStudent;
                    $activePhoto = $activeStudent.data('photo');
                    $activeStudent.addClass('is-active');
                    $activePhoto && $activePhoto.addClass('is-active');
                }

                if ($newActiveHeader) {
                    $activeHeader = $newActiveHeader;
                    $activePhoto = null;
                    $headers.addClass('is-active');
                }

                return;

                $students.add($photos).removeClass('is-active');
                if ($student) {
                    var $photo = $student.data('photo');
                    $student.add($photo).addClass('is-active');
                }
            };

            // Snap to closest item

            var hasScrollEnded = false;
            var isTouchHeld = false;
            var isSnapping = false;

            var snapToClosestListItem = function () {
                if (isSnapping) return;
                if (isTouchHeld) return;

                var offset = {
                    left: markerPos.x,
                    top: markerPos.y,
                };

                var $closest = $listItems.closestToOffset(offset);

                if ($closest.length) {
                    var currentScroll = $container.scrollTop();
                    var newScroll = currentScroll + $closest.offset().top + (-markerHeight / 2) + ($closest.outerHeight() / 2);
                    var delta = Math.abs(currentScroll - newScroll);
                    if (delta <= 1.5) {
                        return;
                    }

                    isSnapping = true;
                    $container.scrollTo($closest, 200, {
                        offset: {
                            top: (-markerHeight / 2) + ($closest.outerHeight() / 2),
                        },
                        onAfter: function() {
                            setTimeout(function() {
                                isSnapping = false;
                            }, 50);
                        }
                    })
                }
            };



            // On scroll: find and mark current student / header
            $container.on('scroll', $.throttle(100, findAndSetActiveListItem));

            // On scroll stop: snap to closest item
            $container.on('touchmove', function(){
                isTouchHeld = true;
                hasScrollEnded = false;
            });
            $container.on('touchend', function(){
                isTouchHeld = false;
                if (hasScrollEnded) {
                    snapToClosestListItem();
                    hasScrollEnded = false;
                }
            });
            $container.on('scrollstop', 50, function () {
                hasScrollEnded = true;
                snapToClosestListItem();
            });

            return;
            $students.each(function () {
                var $student = $(this),
                    imageRef = $student.data('image-ref'),
                    $photo = $photos.filter(function () {
                        return $(this).data('image-id') === imageRef;
                    });
                $student.data('photo', $photo);
            });
        });

        // Click link: switch list

        var $links = $namespace.find(selectors.studentListLink);

        $links.each(function () {
            var $link = $(this),
                status = $link.data('link-status'),
                $list = $lists.filter(function () {
                    return $(this).data('list-status') === status;
                }),
                $container = $list.find(selectors.studentListScroll);

            $link.on('click', function () {
                $links.removeClass('is-active');
                $link.addClass('is-active');
                $lists.removeClass('is-active');
                $list.addClass('is-active');
                $photos.removeClass('is-active');
                $container.scrollTop(0);
            });
        });
    },

    setupFakeEndlessScrolling = function (element) {
        // Initialize elements
        var $container = $(element),
            $wrapper = $container.children().wrapAll('<div class="endless endless--fake"></div>').parent(),
            scrollHeight = $wrapper.outerHeight(),
            $clones = $wrapper.children().clone().wrapAll('<div class="clones"></div>').parent().appendTo($wrapper);
        $clones.clone().appendTo($wrapper);
        $clones.clone().appendTo($wrapper);
        $container.scrollTop(scrollHeight * 2);
    },

    setupEndlessScrolling = function(element) {

        // Initialize elements
        var $container = $(element),
            $wrapper   = $container.children().wrapAll('<div class="endless"></div>').parent(),
            $clones    = $wrapper.children().clone().wrapAll('<div class="clones"></div>').parent().appendTo($wrapper),
            scrollPos = 0, lastScrollPos = 0, scrollDelta = 0,
            disableScroll, scrollHeight, clonesHeight;

        function reCalc() {
            scrollPos    = $container.scrollTop();
            scrollHeight = $wrapper.outerHeight();
            clonesHeight = $clones.outerHeight();
            $container.data('scrollHeight', scrollHeight);

            if (scrollPos <= 0) {
                $container.scrollTop(1); // Scroll 1 pixel to allow upwards scrolling
            }
        }

        function scrollUpdate() {
            if (!disableScroll) {
                lastScrollPos = scrollPos;
                scrollPos     = $container.scrollTop();
                scrollDelta   = scrollPos - lastScrollPos;

                if (clonesHeight + scrollPos >= scrollHeight) {
                    // if ($container.hasClass('right'))
                    // console.log('BOTTOM ' + $container[0].className.split(' ')[1] + ' -- clones + scrollTop '+(clonesHeight+scrollPos)+' >= scrollHeight '+scrollHeight+' ');
                    // Scroll to the top when you’ve reached the bottom
                    $container.scrollTop(1); // Scroll down 1 pixel to allow upwards scrolling
                    disableScroll = true;
                } else if (scrollPos <= 0) {
                    // console.log('TOP    ' + $container[0].className.split(' ')[1] + ' -- scrollTop '+(scrollPos)+'<= 0');
                    // Scroll to the bottom when you reach the top
                    $container.scrollTop(scrollHeight - clonesHeight);
                    disableScroll = true;
                }
            }

            if (disableScroll) {
                // Disable scroll-jumping for a short time to avoid flickering
                window.setTimeout(function() {
                    disableScroll = false;
                }, 20);
            }
        }

        reCalc();

        $(window).resize(function() {
            requestAnimationFrame(reCalc);
        });

        $container.scroll(function() {
            requestAnimationFrame(scrollUpdate);
        });
    },

    flashProjectMedia = function() {
        $('html').addClass('is-project-media-flashing');
        setTimeout(function () {
            $('html').removeClass('is-project-media-flashing');
            var date = new Date();
            date.setTime(date.getTime() + (15 * 24 * 60 * 60 * 1000)); // 15 days
            document.cookie = "hasvisitedproject=1; expires=" + date.toGMTString() + "; path=/";
        }, 1500);
    },

    setupProjectMedia = function(){
        var $project = $(selectors.project).once();
        var $lightbox = $(selectors.projectLightbox).once();
        if (!$project.length) return;

        var $container = $project.find(selectors.projectMediaContainer),
            $media = $container.find(selectors.projectMedia),
            $toggle = $project.find(selectors.projectMediaToggle),
            $close = $lightbox.find(selectors.projectLightboxClose);

        // Show media list on click
        $toggle.on('click', function () {
            $e.body.toggleClass('is-project-media-visible');
        });

        // "Flash" media list on first visit
        if (!document.cookie.match('(^|;) ?hasvisitedproject=1(;|$)')) {
            $(window).on('load', function() {
                flashProjectMedia();
            });
        }

        // Close lightbox
        $close.on('click', function () {
            $e.body.removeClass('is-lightbox-visible');
        });

        if (!$media.length) return;

        var $firstObject = $media.first().find('img, iframe').first(),
            objectHeight = $firstObject.height(),
            objectCenter = $firstObject.position().top + objectHeight/2,
            windowCenter = $(window).height() / 2,
            centerDistance = windowCenter - objectCenter,
            $slideshow = $lightbox.find(selectors.projectMediaSlideshow),
            swiper = $slideshow.get(0).swiper;

        // Add margin top to center image visually
        if (centerDistance > 25) {
            $container.css('padding-top', centerDistance);
        }

        // Open lightbox on click
        $media.on('click', function () {
            $e.body.addClass('is-lightbox-visible');
            swiper.slideToLoop($(this).index(), 0);
        });

        // Show caption on hover
        $media.on('mouseenter', function () {
            var $m = $(this),
                $caption = $m.find('figcaption'),
                captionHeight = Math.ceil($caption.outerHeight() || 0);
            $m.get(0).style.setProperty('--caption-offset', (-captionHeight) + 'px');
            $m.addClass('is-caption-visible');
        });
        $media.on('mouseleave', function () {
            var $m = $(this);
            $m.removeClass('is-caption-visible');
        });
    },

    setupProjectList = function () {
        var $namespace = $(selectors.projectList).once();
        if (!$namespace.length) return;

        var $content = $namespace.find(selectors.projectListContent),
            $columns = $namespace.find(selectors.projectListColumn),
            $left = $namespace.find(selectors.projectListScrollLeft),
            $right = $namespace.find(selectors.projectListScrollRight),
            $categoryLinks = $namespace.find(selectors.projectListCategoryLink);

        var columnCount = $columns.length,
            columnWidth = 0,
            scrollLeft,
            scrollMax,
            scrollIndex = 0,
            scrollIndexOffset = 0;

        $(window).on('resizestop.projectlist', 100, function () {
            columnWidth = $columns.first().outerWidth();
            scrollMax = $content.get(0).scrollWidth - $content.get(0).clientWidth;
        }).trigger('resizestop.projectlist');

        $content.on('scroll', $.throttle(100, function () {
            scrollLeft = $content.scrollLeft();
            scrollIndex = Math.floor(scrollLeft / columnWidth);
            scrollIndexOffset = scrollLeft - scrollIndex * columnWidth;

            $left.toggleClass('is-enabled', scrollLeft > 20);
            $right.toggleClass('is-enabled', scrollLeft <= scrollMax - 20);
        })).trigger('scroll');

        $left.click(function () {
            var newIndex = scrollIndexOffset > 50 ? scrollIndex : scrollIndex - 1;
            var scrollTo = Math.max(0, newIndex) * columnWidth;
            $content.animate({
                scrollLeft: scrollTo,
            }, config.smoothScrollingSpeed);
        });

        $right.click(function () {
            var scrollTo = Math.min(columnCount, scrollIndex + 1) * columnWidth;
            $content.animate({
                scrollLeft: scrollTo,
            }, config.smoothScrollingSpeed);
        });

        var filterByCategory = function(category) {
            $columns.each(function(){
                var $column = $(this);
                var $allProjects = $column.find(selectors.projectPreview);
                var $filteredProjects = category ? $allProjects.filter('[data-category="' + category + '"]') : $allProjects;
                $allProjects.addClass('is-hidden');
                $filteredProjects.removeClass('is-hidden');
                $column.toggleClass('is-hidden', !$filteredProjects.length);
            });
            $columns.removeClass('is-first-visible');
            $columns.removeClass('is-last-visible');
            $columns.not('.is-hidden').first().addClass('is-first-visible');
            $columns.not('.is-hidden').last().addClass('is-last-visible');
            $content.scrollLeft(0).trigger('scroll');
        };

        $categoryLinks.on('click', function (event) {
            event.preventDefault();
            var $link = $(this);
            var category = $link.data('category');

            if ($link.hasClass('is-active')) {
                $link.removeClass('is-active');
                filterByCategory();
            }
            else {
                $categoryLinks.removeClass('is-active');
                $link.addClass('is-active');
                filterByCategory(category);
            }
        });
    },

    setupWorkList = function () {
        var $namespace = $(selectors.workList).once();
        if (!$namespace.length) return;

        var $indexToggle = $namespace.find(selectors.workListIndexToggle),
            $indexThumbs = $namespace.find(selectors.workListIndexThumb),
            $slideshow = $namespace.find(selectors.workListSlideshow),
            swiper = $slideshow.get(0).swiper;

        $indexToggle.click(function(){
            $e.body.toggleClass('is-work-index-visible');
        });

        $indexThumbs.click(function(){
            $e.body.removeClass('is-work-index-visible');
            swiper.slideToLoop($(this).index(), 0);
        });
    },

    setupPersonList = function () {
        var $containers = $(selectors.personList).once();
        if (!$containers.length) return;

        var getOuterBounds = function($wrappers) {
            return {
                offset: Math.min.apply(null, $wrappers.map(function(){
                    return $(this).offsetParent().position().top;
                })),
                top: Math.min.apply(null, $wrappers.map(function(){
                    return $(this).position().top;
                })),
                bottom: Math.max.apply(null, $wrappers.map(function(){
                    return $(this).position().top + $(this).outerHeight();
                })),
            };
        };

        $containers.each(function () {

            var $container = $(this),
                $page = $container.closest(selectors.page),
                $wrapper = $container.closest(selectors.personListContentWrapper),
                $prevWrappers = $wrapper.prevAll(selectors.personListContentWrapper),
                $nextWrappers = $wrapper.nextAll(selectors.personListContentWrapper),
                $allWrappers = $prevWrappers.add($wrapper).add($nextWrappers),
                $newWrappers = $allWrappers.filter(function() {
                    return $(this).data('setup') !== true;
                }),
                $persons = $newWrappers.find(selectors.personListPerson),
                bounds;

            if (!$newWrappers.length) return;
            $newWrappers.data('setup', true);

            $(window).on('resizestop.personlist', 0, function(){
                bounds = getOuterBounds($allWrappers);
            }).trigger('resizestop.personlist');

            $persons.each(function(index){
                var $person = $(this),
                    $toggle = $person.find(selectors.personListInfoToggle),
                    $info = $person.find(selectors.personListInfo),
                    infoPosition, infoHeight, infoBottom;

                if (!$info.length) return;

                $(window).on('resizestop.person' + index, 0, function(){
                    infoHeight = $info.outerHeight();
                    infoPosition = Math.max(bounds.top, $toggle.position().top);
                    infoPosition = Math.min(infoPosition, bounds.bottom - infoHeight);
                    $info.css('top', infoPosition);
                }).trigger('resizestop.person' + index);

                $toggle.click(function(){
                    if (!$person.hasClass('is-toggled')) {
                        infoPosition = Math.max(bounds.top, $page.scrollTop());
                        infoPosition = Math.min(infoPosition, bounds.bottom - infoHeight);
                        $info.css('top', infoPosition);
                    }
                    $persons.not($person).removeClass('is-toggled');
                    $person.toggleClass('is-toggled');
                });
            });
        });
    };


    // Public properties and methods
    return {
        init: init,
    };

})();

// General site/app module

$(function() {

    // Initialize media query detection

    MediaQuery.init();

    // Load up app and initialize

    App.init();

});
